import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom"
import NavBar from "../../App/UX/NavBar";


export function Layout () {
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    console.log('Corro useEffect layout')
    const user = localStorage.getItem('user') || null

    if(!user){
        console.log('Uusario en layout-->', user);
        setIsLoading(false)
    }else{
        console.log('Uusario en layout-->', user);
        setIsLoading(false)
    }

  }, [])

  return (
    <>
    {!isLoading && (
    <>
    <NavBar position='relative'/>
      <Outlet />
    </>
    )}
    </>
  )
}