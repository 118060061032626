import React, { useEffect, useState, useRef } from "react";
import { Page, Text, View, Document, Image, StyleSheet, Font, PDFDownloadLink, BlobProvider, pdf } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// import QRCode from "qrcode";
//import RequestsService from "../../../services/RequestsService";
// import CircularLoading from "../../UX/CircularLoading";
import { Button, Typography } from "@mui/material";
import Saira from "../../../fonts/Saira-Regular.ttf";
import SairaBold from "../../../fonts/Saira-Bold.ttf";
import quotesServices from "../../../services/quotesService";
import axios from "axios";
import QuotePdf from "./quotePDF";
import SweetAlertCreate from "../../UX/Alerts/SweetAlertCreate";


const DownloadInternalQuotePDF = ({QuoteInfo = {}, sendEmail = false, clientEmail = ''}) => {
    const [t, i18n] = useTranslation("global");
    const { state } = useLocation();
    const [assets, setAssets] = useState([])
    const qrBaseUrl = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, seterrorMessage]= useState('');
    const [QuoteData, setQuoteData] = useState({});
    const fileRef = useRef(null);

    const FormatDate = (date)=>{
        return(`${new Date(date).toLocaleDateString('es', {timeZone: 'America/Mexico_City'})} `)
    }

    const Today = FormatDate(new Date);
    const styles1 = StyleSheet.create({
        page: {
          flexDirection: "column",
          backgroundColor: "#ffffff",
          fontFamily: "Saira",
          paddingHorizontal: 40,
          paddingVertical: 20,
          fontSize: 11,
        },
        row: {
          display: "flex",
          flexDirection: "row",
        },
        col: {
          display: "column",
        },
        rowR: {
          display: "flex",
          flexDirection: "row-reverse",
        },
        alignCenter: { display: "flex", alignItems: "center" },
        tAliRight: {
          textAlign: "right",
        },
        bold: {
          fontWeight: "bold",
        },
        title: {
          fontWeight: "bold",
          fontSize: 24,
        },
        text: {
          fontWeight: "regular",
          fontSize: 24,
        },
        line: {
          backgroundColor: "#d9dadb",
          height: 1,
          marginTop: "15px",
          marginBottom: "10px",
        },
        table: { 
          display: "table", 
          width: "auto", 
          borderStyle: "solid", 
          borderColor:'#d7dbe0',
          borderWidth: 1, 
          borderRightWidth: 0, 
          borderBottomWidth: 0 ,
          borderLeftWidth: 0 
    
        }, 
        tableRow: { 
          flexDirection: "row" 
        }, 
        tableCol: { 
          width: "100%", 
          borderStyle: "solid", 
          borderWidth: 1, 
          borderLeftWidth: 0, 
          borderColor:'#d7dbe0',
          borderTopWidth: 0,
          borderRightWidth: 0, 
          borderBottomWidth: 0,
        }, 
        tableCell: { 
          margin: "auto", 
          marginTop: 0, 
          marginBottom: 0, 
          fontSize: 10 
        }, 
        tableCell1: { 
          marginTop: 0, 
          marginBottom: 0, 
          fontSize: 11,
          fontWeight:'bold'
        },
        tableCell2: { 
          marginTop: 14,
          marginBottom: 4, 
          marginLeft:250,
          fontSize: 10,
          fontWeight:'bold'
        }
    })

    const listOfCountries = [
        {
            ID: 'MX',
            CompanyInvoice: 'SOFTWARE PARA MANTENIMIENTO MP',
            address: 'REFORMA 7, CD BRISA, NAUCALPAN. ESTADO DE MÉXICO, MÉXICO',
            IVALabel: 'IVA 16%',
            format:'EN',
        },
        {
            ID: 'CO',
            IVALabel:'Exluido de IVA*',
            address: 'Calle 34B Nro. 65D-02, Of 201, Medellín-Antioquia, COLOMBIA',
            CompanyInvoice: 'MPSOFTWARE COLOMBIA SAS',
            format:'es'
        },
        {
            ID: 'CL',
            CompanyInvoice: 'MPSOFTWARE CHILE',
            address:'MARCHANT PEREIRA 150 OFICINA 901, COMUNA DE PROVIDENCIA, SANTIAGO, CHILE',
            IVALabel:'IVA 19%',
            format:'es'
        }, 
        {
            ID: '00',
            CompanyInvoice: 'MPSERVICES LLC',
            address:'30 N GOULD ST STE SHERIDAN WY 82801',
            IVALabel:'No incluye Impuestos',
            format:'EN'
        }
    ]

    Font.register({
        family: "Saira",
        fonts: [
            {
            src: Saira,
            },
            {
            src: SairaBold,
            fontWeight: "bold",
            },
        ],
    });

    useEffect(()=> {
        const selectedCountry = listOfCountries.find(country => country.ID == QuoteInfo.country);
        const data = {...QuoteInfo, ...selectedCountry}
        setQuoteData(data)
        data.priceXdiscount = QuoteInfo.discount > 0 ? (QuoteInfo.subTotal * ((100  - QuoteInfo.discount)/100)) : QuoteInfo.subTotal
        setIsLoading(false)
    }, [])

    const sendPDF = async() => {
        const formData = new FormData();
        try{
            const blob = await pdf(<QuotePdf QuoteData={QuoteData}/>).toBlob();
            formData.append('attach',blob);
            formData.append('client_email',clientEmail);
            formData.append('lan',localStorage.getItem('i18nextLng'));
            const url = process.env?.REACT_APP_LOCAL_ENVIRONMENT === 'TRUE' ?  `http://localhost:3000/quotes/sendQuote` : `${process.env?.REACT_APP_BACK}/quotes/sendQuote`
            const res = await axios.post(url, formData, {
                headers: {
                  'Auth': `${localStorage.getItem('token')}`,
                  'Content-Type': 'multipart/form-data'
                }
            })
            SweetAlertCreate(t('alert.sentEmail'))
        }catch(error){
            console.log('Error', error)
        } 
    }

    // const doc = (
    //     <Document>
    //     <Page size="A4" style={styles1.page}>
    //       <View>
    //         <Image src="https://mpservices.s3.us-east-2.amazonaws.com/internal/mpservices+negro.png" style={{height: "30", width:'125'}}/>
    //         <View style={styles1.rowR}>
    //             <Text style={[styles1.bold,{fontSize:15}]}>Cotización</Text>
    //         </View>
    //         <View style={styles1.rowR}>
    //             <Text >Fecha: {Today}</Text>
    //         </View>
    //         <View style={styles1.rowR}>
    //             <Text >Folio: {QuoteData.q_folio}</Text>
    //         </View>
    //       </View>
    //       <View style={styles1.line} />
    //       <View style={styles1.row}>
    //         <Text style={{color:'grey'}}>De:</Text>
    //         </View>
    //         <View style={styles1.row}>
    //         <Text>
    //           {QuoteData.CompanyInvoice}
    //         </Text>
    //       </View>
    //       <View style={[styles1.row, {marginTop:6}]}>
    //         <Text style={{color:'grey'}}>Para:</Text>
    //         </View>
    //         <View style={styles1.row}>
    //         <Text>
    //           {QuoteData.company}
    //         </Text>
    //       </View>
    //       <View style={styles1.row}>
    //         <Text>
    //           {QuoteData.contact}
    //         </Text>
    //       </View>
    //       <View style={styles1.line} />
        
    //     <View style={styles1.table}>
    //         {/**Header */} 
    //         <View style={styles1.tableRow}> 
    //         <View style={[styles1.tableCol, {width:'65%',  borderRightWidth: 1, backgroundColor:'#F5BD41'}]}> 
    //         <Text style={[styles1.tableCell,{fontWeight: 'bold', fontSize:11 }]}>Descripción del Producto</Text> 
    //         </View> 
    //         <View style={[styles1.tableCol, {width:'35%', backgroundColor:'#F5BD41'}]}> 
    //             <Text style={[styles1.tableCell,{fontWeight: 'bold', fontSize:11}]}>Precio</Text> 
    //         </View> 
    //         </View>
        
    //         {/* Product Rows */}
    //         <View style={styles1.tableRow}>
    //             {
    //             QuoteInfo.products.map((product) => {
    //                 return(
    //                 <View style={styles1.tableRow}>
    //                     <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1, }]}>
    //                         <Text style={styles1.tableCell1}>
    //                         {product.name}
    //                         </Text>
    //                         <Text>
    //                             {product.description}
    //                         </Text>
    //                     </View>
    //                     <View style={[styles1.tableCol, {width:'35%'}]}>
    //                         <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
    //                             {getCurrencyFormat(product.unit_cost[QuoteData.currency])}
    //                         </Text>
    //                     </View>
    //                 </View>
    //                 )
    //             })
    //             } 
    //         </View>

    //         <View style={[styles1.tableRow]}> 
    //             <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1 }]}>
    //                 <Text style={[styles1.tableCell2,{justifyContent:'flex-end'}]}>
    //                 subtotal
    //                 </Text>  
    //             </View> 
    //             <View style={[styles1.tableCol, {width:'35%',  marginTop:10,}]}>
    //                 <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
    //                     {getCurrencyFormat(QuoteData.subTotal)}
    //                 </Text> 
    //             </View> 
    //         </View> 

    //         {/* Discount */}
    //         {QuoteData.discount > 0 && (
    //         <View style={[styles1.tableRow]}> 
    //             <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1 }]}>
    //                 <Text style={[styles1.tableCell2,{justifyContent:'flex-end'}]}>
    //                 Descuento {QuoteData.discount}%
    //                 </Text>  
    //             </View> 
    //             <View style={[styles1.tableCol, {width:'35%',  marginTop:10,}]}>
    //                 <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
    //                     {getCurrencyFormat(QuoteData.subTotal * (QuoteData.discount/100))}
    //                 </Text> 
    //             </View> 
    //         </View> 
    //         )} 
            
    //         {/* Taxes */}
    //         {QuoteData.currency != 'USD' && (
    //         <View style={[styles1.tableRow]}> 
    //             <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1 }]}>
    //                 <Text style={[styles1.tableCell2,{justifyContent:'flex-end'}]}>
    //                 Impuesto {QuoteData.tax}%
    //                 </Text>  
    //             </View> 
    //             <View style={[styles1.tableCol, {width:'35%',  marginTop:10,}]}>
    //                 <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
    //                     {getCurrencyFormat(QuoteData.priceXdiscount * (QuoteData.tax/100))}
    //                 </Text> 
    //             </View> 
    //         </View> 
    //         )}

    //         <View style={[styles1.tableRow]}> 
    //             <View style={[styles1.tableCol, {width:'65%', borderRightWidth: 1 }]}>
    //             <Text style={[styles1.tableCell2,{marginRight:'flex-end'}]}>
    //             Total 
    //             </Text>  
                
    //             </View> 
    //             <View style={[styles1.tableCol, {width:'35%',  marginTop:10, borderTopWidth:1}]}>
    //                 <Text style={[styles1.tableCell,{justifyContent:'flex-end'}]}>
    //                     {getCurrencyFormat(QuoteData.total)}
    //                 </Text>   
    //             </View> 
    //         </View> 
    //     </View>
    //     <View style={styles1.row}>
    //         <Text style={[styles1.bold,{fontSize:11, marginTop: 40}]}>Forma de Pago:</Text>
    //     </View>
    //     <View style={styles1.row}>
    //     <Text style={{fontSize:11}}> {getPaymentTerms(QuoteData.payment)}</Text>
    //     </View>

    //     {QuoteData.ID=='CO' && (
    //     <View style={styles1.row}>
    //     <Text style={{fontSize:10}}> * Según numeral 21 del ART.476 del E.T</Text>
    //     </View>
    //     )}

    //     {QuoteData.currency=='USD' && (
    //     <View style={styles1.row}>
    //     <Text style={{fontSize:10}}> * Precios en USD. Lo precios no incluyen impuestos</Text>
    //     </View>
    //     )}

    //     <View style={[styles1.row ,{justifyContent:'center', marginTop:'100px'}]}>
    //         <Text style={{fontSize:10}}> {QuoteData.CompanyInvoice}</Text>
    //     </View>
    //     <View style={[styles1.row ,{justifyContent:'center', marginTop:'2px'}]}>
    //         <Text style={{fontSize:10}}> {QuoteData.address}</Text>
    //     </View>
    //     </Page>
    //   </Document>
    // )

  return (
    <>
    {!isLoading &&
    (sendEmail ?
        <div>
            <Button sx={{marginTop: '6px'}} variant='contained' size="small" onClick={()=>sendPDF()}>
                <Typography variant="yellowButton">{t("general.send")}</Typography>
            </Button>
        </div>
    :
        <div>
        <PDFDownloadLink
            document={<QuotePdf QuoteData={QuoteData}/>}
            fileName={`${t("quote.quote")}-${QuoteData.q_folio}`}
            id="pdfquote"
        >
            {({ blob, url, loading, error }) =>{
            //setPdfLoad(loading)
                return loading ? 
                <Typography>Cargando...</Typography> : 
                <Button sx={{marginTop: '6px'}} size="small" variant="contained"> <Typography variant="yellowButton">{t("general.download")}</Typography></Button>
            }
            }
        </PDFDownloadLink>
        </div>)
    }
</>
  );
};

export default DownloadInternalQuotePDF;