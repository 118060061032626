import React, { Fragment, useEffect, useState } from "react";
import {useNavigate, useParams, useLocation } from "react-router-dom"; 
import {useSelector} from 'react-redux';
import { useForm, Control, useFieldArray, useWatch } from "react-hook-form";
import {useTranslation} from "react-i18next"

import { Box, Button, Divider, Grid, TextField, Toolbar, Tooltip, Typography, Autocomplete, MenuItem, Select } from "@mui/material";
import Paper from '@mui/material/Paper';
import countries from "./Helpers/countries";
import products from "./Helpers/products";
import quotesServices from "../../services/quotesService";
import DownloadInternalQuotePDF from "./Helpers/DownloadInternalQuotePDF";
import ErrorMessage from "../UX/errorMessage";
import GeneralQuoteHelper from "./Helpers/generalQuote.helper";

const boxStyle={
    padding:'10px 0px 0px 14px',
    display: 'flex',
    flexWrap: 'wrap',
    '& > :not(style)': {
    width: '100%',
    minWidth:140,
    minHeight: 200,
    borderRadius: 3
    },
}

export default function QuoteDetail() {

    const navigate = useNavigate();
    const [country, setCountry] = useState();
    const [selectedProducts, setSelectedProducts] = useState([{name: 'product',quantity: 0, id:'9383', unit_cost: {USD: 0, CLP: 0, COP: 0, MXN: 0} }]);
    const [allProducts,setAllProducts] = useState(products) 
    const [t, i18n] = useTranslation("global");
    const [discount, setDiscount] = useState(0);
    const [taxes, setTaxes] = useState(0);
    const [subTotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [currency, setCurrency] = useState('USD');
    const [Quote, setQuote] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    let params = useParams();
    const {state} = useLocation();

    useEffect(() => {
        console.log('id', params.id);
        console.log('state', state);

        const fetchData = async() => {
            try{
                const res = await quotesServices.getOneQuote({quote_id: params.id})
                console.log('res', res);
                const quote = res.quote;
                console.log('pais', countries.find((con) => con.code === quote.country));
                setCountry(countries.find((con) => con.code === quote.country));
                console.log('Productos', quote.products)
                setSelectedProducts(quote.products);
                setDiscount(quote.discount);
                setTaxes(quote.tax);
                setSubtotal(quote.subTotal);
                setTotal(quote.total);
                //setQuote({company: quote.company, contact_name: quote.contact_name, email: quote.email})
                setQuote(res.quote)
                setCurrency(quote.currency);
                setIsLoading(false);

            }catch(error){
                console.log('ERROR-->', error);
                setErrorMessage(error);
            }
        }

        if(!state?.newQuote){
            fetchData();
        }
    }, [])

    const editQuote = () => {
        navigate(`/EditQuote/${params.id}`);
    }

return (
    <>  
        {errorMessage && (
            <ErrorMessage errorMessage={errorMessage} /> )}

        {isLoading ?
        <Grid></Grid> 
        :
        <>
        {/* Header */}
        <Grid container>
            <Grid item xs={2}>
            <Typography variant='subtitle1' padding='10px '>
            {t("quote.quote")}
            </Typography>
            </Grid>
            <Grid item container paddingRight='20px' xs={10} justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                    <DownloadInternalQuotePDF QuoteInfo={Quote} sendEmail={true} clientEmail={Quote.email}/>
                </Grid>
                <Grid item>
                    <DownloadInternalQuotePDF QuoteInfo={Quote}/>
                </Grid>
                <Grid item>
                    <Button sx={{marginTop: '6px'}} onClick={editQuote} size="small" variant="contained">
                        <Typography variant="yellowButton">
                        {t("general.edit")}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
        <Divider/>
        {/* Body */}
        <Box sx={boxStyle}>
        <Paper elevation={0} border='solid 1px'>
        {/* Info */}
        <Grid container>
            <Grid container item xs={12} md={6}>
                {/* Country Field */}
                <Grid container margin='10px 0' display='flex' spacing={1} width='100%'>
                    <Grid item container xs={4} md={3} margin='auto 0' padding='0px 20px' justifyContent='flex-end'> 
                        <Typography variant='generalDataFieldName' >
                        {t("general.country")}:
                        </Typography>
                    </Grid>
                    <Grid  item container xs={8} md={9}> 
                        <Autocomplete
                        disabled
                        id="country-select-demo"
                        marginright='10px'
                        sx={{ width: '90%'}}
                        options={countries}
                        size='small'
                        value={country}
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.label} ({option.code})
                            </Box>
                        )}
                        renderInput={(params) => (
                            <>
                            <TextField
                            {...params}
                            label={t("general.country")}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            />
                            </>

                        )}
                        />
                    </Grid> 
                </Grid>
                {/* Company Name */}
                <Grid container margin='10px 0' display='flex' spacing={1} width='100%'>
                    <Grid item container xs={4} md={3} margin='auto 0' padding='0px 20px' justifyContent='flex-end'> 
                        <Typography variant='generalDataFieldName' >
                        {t("general.company")}:
                        </Typography>
                    </Grid>
                    <Grid  item container xs={8} md={9}> 
                        <TextField
                        name={'company_name'}
                        required
                        style={{background: '#FFFFFF', minWidth:'70px',width: '90%'}}
                        size='small'
                        type='text'
                        InputProps={{ maxLength: 250 }}
                        value={Quote.company}                                         
                        />
                    </Grid> 
                </Grid>
                {/* Contact Name */}
                <Grid container margin='10px 0' display='flex' spacing={1} width='100%'>
                    <Grid item container xs={4} md={3} margin='auto 0' padding='0px 20px' justifyContent='flex-end'> 
                        <Typography variant='generalDataFieldName' >
                        {t("general.contact_name")}:
                        </Typography>
                    </Grid>
                    <Grid  item container xs={8} md={9}> 
                        <TextField
                        name={'contact_name'}
                        required
                        style={{background: '#FFFFFF', minWidth:'70px',width: '90%'}}
                        size='small'
                        type='text'
                        InputProps={{ maxLength: 250 }}
                        value={Quote.contact_name}                                        
                        />
                    </Grid> 
                </Grid>
                {/* contact Email */}
                <Grid container margin='10px 0' display='flex' spacing={1} width='100%'>
                    <Grid item container xs={4} md={3} margin='auto 0' padding='0px 20px' justifyContent='flex-end'> 
                        <Typography variant='generalDataFieldName' >
                        {t("general.email")}:
                        </Typography>
                    </Grid>
                    <Grid  item container xs={8} md={9}> 
                        <TextField
                        name={'email'}
                        required
                        style={{background: '#FFFFFF', minWidth:'70px',width: '90%'}}
                        size='small'
                        type='email'
                        InputProps={{ maxLength: 250 }}
                        value={Quote.email}                                         
                        />
                    </Grid> 
                </Grid> 
            </Grid>
        </Grid>
        {/* Products */}
        <Grid container>
            <Grid container item xs={12} sx={{padding: '10px', paddingLeft: '20px'}}>
                <Typography variant='subtitle1' padding='10px '>
                {t("product.products")}
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{padding: '10px', paddingLeft: '20px'}}>
                {selectedProducts.map((product, index) => {
                    return(
                        <Grid container direction="row" justifyContent="felx-start" alignItems="center" spacing={3} sx={{paddingBottom: '10px'}}  key={product.id}>
                            {/**Producto */}
                            <Grid item>
                            <Autocomplete
                                disabled
                                value={selectedProducts[index]}
                                id="controllable-states-demo"
                                options={allProducts}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label={t("product.product")} />}
                                getOptionLabel={(option) => option.name}
                            />
                            </Grid>
                            {/**Cantidad */}
                            <Grid item>
                            <TextField
                            name={'quantity'}
                            required
                            label={t("product.quantity")}
                            style={{background: '#FFFFFF', minWidth:'70px'}}
                            type='number'
                            //InputProps={{ maxLength: 250 }}
                            value={product.quantity}                                      
                            />
                            </Grid>
                            {/** Costo unitario*/}
                            <Grid item>
                                <TextField
                                name={'unitario'}
                                label={t("product.unit_cost")}
                                style={{background: '#FFFFFF', minWidth:'70px'}}
                                type='number'
                                value={product.unit_cost[currency]}                                      
                                />
                            </Grid>
                            {/**Total */}
                            <Grid item >
                                <Grid item>Total</Grid>
                                <Grid item>{GeneralQuoteHelper.getCurrencyFormat(product.quantity * product.unit_cost[currency], currency)}</Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
        {/**Final Data */}
        <Grid container spacing={2} paddingBottom={'50px'} width={'90%'}>
            {/**Subtotal */}
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}> 
                <Grid item >Subtotal</Grid>
                <Grid item xs={4}>
                    {GeneralQuoteHelper.getCurrencyFormat(subTotal,currency)}
                </Grid>
            </Grid>
            {/**Discount */}
            <Grid item container  direction="row" justifyContent="flex-end" alignItems="center" spacing={2}> 
                <Grid item >{t("general.discount")} %</Grid>
                <Grid item xs={4}>
                    <TextField
                    name={'discount'}
                    style={{background: '#FFFFFF', minWidth:'70px',width: '50%'}}
                    size='small'
                    type='number'
                    InputProps={{ maxLength: 250 }}
                    value={discount}    
                    />
                </Grid>
            </Grid>
            {/**Taxes */}
            <Grid item container  direction="row" justifyContent="flex-end" alignItems="center" spacing={2}> 
                <Grid item >{t("general.taxes")} %</Grid>
                <Grid item xs={4}>
                    <TextField
                    name={'impuesto'}
                    style={{background: '#FFFFFF', minWidth:'70px',width: '50%'}}
                    size='small'
                    type='number'
                    InputProps={{ maxLength: 250 }}
                    value={taxes}                                     
                    />
                </Grid>
            </Grid>
            {/**Payment terms */}
            <Grid item container  direction="row" justifyContent="flex-end" alignItems="center" spacing={2}> 
                <Grid item >Términos de pago</Grid>
                <Grid item xs={4}>
                <Select
                    disabled
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={Quote.payment}
                    style={{background: '#FFFFFF', minWidth:'70px',width: '50%'}}
                    label="payment"
                >
                    <MenuItem value={0}>Pago a la recepción</MenuItem>
                    <MenuItem value={15}>15 días</MenuItem>
                    <MenuItem value={30}>30 días</MenuItem>
                    <MenuItem value={60}>60 días</MenuItem>
                    <MenuItem value={90}>90 días</MenuItem>
                    <MenuItem value={'endMonth'}>Al final del mes</MenuItem>
                    <MenuItem value={'endNextMont'}>Al final del siguiente mes</MenuItem>
                </Select>
                </Grid>
            </Grid>
            {/**Total */}
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}> 
                <Grid item >Total</Grid>
                <Grid item xs={4}>
                    {GeneralQuoteHelper.getCurrencyFormat(total,currency)}
                </Grid>
            </Grid>
        </Grid>
        </Paper>
        </Box>
        </>
        }
    </>
)
}