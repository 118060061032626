import React, { Fragment, useEffect, useState, useRef } from "react";
import {useNavigate } from "react-router-dom"; 
import {useSelector} from 'react-redux'
import {useTranslation} from "react-i18next";
import { usePDF, PDFDownloadLink } from '@react-pdf/renderer';

import { DataGridPremium } from '@mui/x-data-grid-premium';
import { 
    GridToolbarContainer, GridToolbarExport, GridToolbarDensitySelector,
    GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton
   } from '@mui/x-data-grid-premium';
import { Box, Button, Divider, Grid, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import quotesServices from "../../services/quotesService";
import ErrorMessage from "../UX/errorMessage";
import QuotePdf from "./Helpers/quotePDF";
import ActionPDFModal from "./components/ActionPDFModal";

const iconButtonStyle={ 
    padding:'0px',
    fontSize: '20px',
    opacity:'20%',
    '&:hover': {
      opacity:'100%',
     color: '#993399'
}}

export default function Quotes(props) {

    const navigate = useNavigate();

    const permissions = useSelector(state => state.authReducer.user.permissions)
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [t, i18n] = useTranslation("global");
    const [quoteData, setQuoteData] = useState({});
    //const quoteData = useRef({});
    //const [instance, updateInstance] = usePDF({ document: <QuotePdf QuoteData={quoteData}/> });
    const [openPDFModal, setOpenPDFModal] = useState(false);

    useEffect(()=> {
        const fetchData = async() => {
            try{
                const res = await quotesServices.fetchAllQuotes();
                console.log('Quotes', res.quotes)
                setRows(res.quotes);
                setIsLoading(false)
            }catch(error){
                console.log('Error', error);
                setErrorMessage(error);
            } 
        }

        fetchData()
    },[])

    const downloadPDF = (quoteData) => {
        setQuoteData(quoteData)
        setOpenPDFModal(true)
    }

    const deleteQuote = async(quote_id) => {
        try{
            const res = await quotesServices.deleteQuote({quote_id});
            let actualRows = [...rows]
            actualRows = actualRows.filter((quote) => quote.quote_id !== quote_id);
            setRows(actualRows);
        }catch(error){
            console.log('Error', error);
            setErrorMessage(error);
        } 
    }

    const columns=[
        { field: 'actions',
        type: 'actions',
        width: 30,
        getActions: (params) => [
                <GridActionsCellItem
                icon={<DeleteIcon sx={iconButtonStyle}/>}
                showInMenu
                label={t("general.delete")}
                onClick={()=>{deleteQuote(params.row.quote_id)}}/>,
            
                <GridActionsCellItem
                icon={<EditIcon sx={iconButtonStyle}/>}
                showInMenu
                label={t("general.edit")}
                onClick={()=>{navigate(`/QuoteDetail/${params.id}`, {state:{request: params.row}})}}/>,

                <GridActionsCellItem
                icon={<PictureAsPdfIcon sx={iconButtonStyle}/> }
                showInMenu
                label={'PDF'}
                onClick={()=>{downloadPDF(params.row)}} />,
          ],
        }, 
        { field: 'q_folio',
        headerName: `Folio`,
        flex: 1, 
        renderCell: (params) => (
            <Grid 
            onClick={ ()=>navigate(`/QuoteDetail/${params.row.quote_id}`)}
            sx={{ cursor: 'pointer' ,
                      '&:hover': {
                        color: '#993399'
                      }}}>
            {params.row.q_folio}
            </Grid> )
        },
        {field: 'company', 
        headerName: `${t("general.company")}`,
        flex: 1},
        {field: 'email', 
        headerName: `${t("general.email")}`,
        flex: 1},
        {field: 'currency', 
        headerName: `${t("general.currency")}`,
        flex: 1},
        {field: 'country', 
        headerName: `${t("general.country")}`,
        flex: 1},
        {field: 'createdAt', 
        headerName: `${t("general.createdAt")}`,
        valueFormatter: (params) => {
            if (params.value == null) {
              return '';
            }
            return dateFormat(params.value);
        },
        flex: 1},
    ]

    const dateFormat = (iso) => {
        var fecha = new Date(iso);

        var dia = fecha.getUTCDate();
        var mes = fecha.getUTCMonth() + 1;
        var anio = fecha.getUTCFullYear();

        return dia + "/" + (mes < 10 ? "0" : "") + mes + "/" + anio
    }

    const CreateQuote = () => {
      navigate('/NewQuote');
    }

    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarQuickFilter/>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
          </GridToolbarContainer>
        );
    }
      

return (
    <>
        {errorMessage && (
        <ErrorMessage errorMessage={errorMessage} /> 
        )}

        {isLoading ?
        <Grid></Grid>
        :
        <>
        <Grid container>
            <Grid item flexGrow={1}>
            <Typography variant='subtitle1' padding='10px '>
            {t("quote.quotes")}
            </Typography>
            </Grid>
        <Grid item marginRight='20px'>
            <Button sx={{marginTop: '6px'}} onClick={()=> CreateQuote()} size="small" variant="contained">
                <Typography variant="yellowButton">
                {t("general.new")}
                </Typography>
            </Button>
        </Grid>
        </Grid>
        <Divider></Divider>
        
        <Grid container>
        <Grid container padding='10px' height={'80vh'}>
            <DataGridPremium 
            rows={rows} 
            columns={columns}
            getRowId={(row) => row.quote_id}
            slots={{ toolbar: CustomToolbar,}}/>
        </Grid>
        </Grid>
        </>
        }

        {openPDFModal && 
            <ActionPDFModal open={openPDFModal} setOpen={setOpenPDFModal} QuoteData={quoteData} />
        }
    </>
)
}