import React, { Fragment, useEffect, useState } from "react";
import {useNavigate } from "react-router-dom"; 
import {useSelector} from 'react-redux';
import { useForm, Control, useFieldArray, useWatch } from "react-hook-form";
import {useTranslation} from "react-i18next"

import { Box, Button, Divider, Grid, TextField, Toolbar, Tooltip, Typography, Autocomplete, MenuItem, Select } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import countries from "./Helpers/countries";
import products from "./Helpers/products";
import quotesServices from "../../services/quotesService";
import ErrorMessage from "../UX/errorMessage";
import GeneralQuoteHelper from "./Helpers/generalQuote.helper";

const boxStyle={
    padding:'10px 0px 0px 14px',
    display: 'flex',
    flexWrap: 'wrap',
    '& > :not(style)': {
    width: '100%',
    minWidth:140,
    minHeight: 200,
    
    borderRadius: 3
    },
}

export default function NewQuote(props) {

    const navigate = useNavigate();
    const userLanguage = localStorage.getItem('i18nextLng')
    const { register, handleSubmit, watch, formState: { errors }, control } = useForm();
    const [country, setCountry] = useState();
    const [selectedProducts, setSelectedProducts] = useState([{name: 'product',quantity: 0, id:'9383', unit_cost: {USD: 0, CLP: 0, COP: 0, MXN: 0}, currency: {USD: 'EN', CLP: 'es', COP: 'es', MXN: 'EN'} }]);
    const [quote, setQuote] = useState({
        type: 'Anual'
    });
    const [allProducts,setAllProducts] = useState([]) 
    const [discount, setDiscount] = useState(0);
    const [taxes, setTaxes] = useState(0);
    const [subTotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [currency, setCurrency] = useState('USD');
    const [errorMessage, setErrorMessage] = useState();
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        setAllProducts(products.filter(product => product.language === userLanguage))
    }, [userLanguage])

      
    const CreateQuote = () => {
      console.log('Hago un Quote');
    }

    const AddProduct = () => {
        if(selectedProducts.length < products.length){
            let newLts = [...selectedProducts]
            newLts.push({name: 'product',quantity: 0, id:'9', unit_cost: {USD: 0, CLP: 0, COP: 0, MXN: 0}})
            setSelectedProducts(newLts);
        }
    }

    const handleCountryChange = (value) => {
        console.log('copntry', value)
        setCurrency(value.currency);
        setCountry(value);
        getsubtotal(value.currency);        
    }
    

    const getsubtotal = (currencyData) => {
        console.log('Corro subtotal')
        const actualCurrency = currencyData ?? currency 
        let subtotal = 0
        selectedProducts.forEach((product) => {
            if(product.quantity && product.unit_cost[actualCurrency])
            subtotal += product.quantity * product.unit_cost[actualCurrency]
        })
        setSubtotal(subtotal)
        getTotal(subtotal, discount, taxes);
    }

    const getTotal = (subTotal, discount, taxes) => {
        let total = subTotal;
        if(discount > 0 && discount < 101){
            total = total * ((100 - discount) /100)
        }
        if(taxes > 0){
            total = total * (1 + (taxes/100))
        }
        console.log('total con descuento', total);
        //total = total * (taxes/100)
        total = Math.round(total * 10) / 10
        setTotal(total);
    }

    const deleteProduct = (index = '') =>{
        let ltsProducts = [...selectedProducts];
        ltsProducts.splice(index, 1);
        setSelectedProducts(ltsProducts);
    }

    const onSubmit = async(data) => {
        //console.log('data a enviar-->', data);
        let sendData = {
            discount: discount,
            tax: taxes,
            subTotal: subTotal,
            total: total,
            products: selectedProducts,
            currency: currency,
            country: country.code
        }
        sendData = {...sendData, ...data}
        console.log('send data-->', sendData);

        try{
            const res = await quotesServices.createQuote(sendData);
            console.log(res)
            // navigate(`/QuoteDetail/${res.newQuote.quote_id}`, {state: {newQuote:res.newQuote}})
            navigate(`/QuoteDetail/${res.newQuote.quote_id}`)
        }catch(error){
            console.log('Error', error)
        } 
    }

return (
    <>  
        {errorMessage && (
            <ErrorMessage errorMessage={errorMessage} /> 
        )}

        {/* Header */}
        <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
            <Grid item flexGrow={1}>
            <Typography variant='subtitle1' padding='10px '>
            {t("quote.quote")}
            </Typography>
            </Grid>
        <Grid item paddingRight='20px'>
            <Button sx={{marginTop: '6px'}} type='submit' size="small" variant="contained">
                <Typography variant="yellowButton">
                {t("general.save")}
                </Typography>
            </Button>
        </Grid>
        </Grid>
        <Divider/>
        {/* Body */}
        <Box sx={boxStyle}>
        <Paper elevation={0} border='solid 1px'>
        {/* Info */}
        <Grid container>
            <Grid container item xs={12} md={6}>
                {/* Country Field */}
                <Grid container margin='10px 0' display='flex' spacing={1} width='100%'>
                    <Grid item container xs={4} md={3} margin='auto 0' padding='0px 20px' justifyContent='flex-end'> 
                        <Typography variant='generalDataFieldName' >
                        {t("general.country")}:
                        </Typography>
                    </Grid>
                    <Grid  item container xs={8} md={9}> 
                        <Autocomplete
                        id="country-select-demo"
                        marginright='10px'
                        sx={{ width: '90%'}}
                        options={countries}
                        size='small'
                        value={country}
                        disableClearable
                        onChange={(e, newValue)=>{handleCountryChange(newValue)}}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                            />

                            {option.code !== '00' ? option.label : t('general.other')} {option.code !== '00' ? (option.code) : ''}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <>
                            <TextField
                            {...params}
                            label={t("general.country")}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            />
                            </>

                        )}
                        />
                    </Grid> 
                </Grid>
                {/* Company Name */}
                <Grid container margin='10px 0' display='flex' spacing={1} width='100%'>
                    <Grid item container xs={4} md={3} margin='auto 0' padding='0px 20px' justifyContent='flex-end'> 
                        <Typography variant='generalDataFieldName' >
                        {t("general.company")}:
                        </Typography>
                    </Grid>
                    <Grid  item container xs={8} md={9}> 
                        <TextField
                        name={'company_name'}
                        required
                        style={{background: '#FFFFFF', minWidth:'70px',width: '90%'}}
                        size='small'
                        type='text'
                        InputProps={{ maxLength: 250 }}
                        defaultValue=''                                         
                        {...register("company", { required: true })}
                        />
                    </Grid> 
                </Grid>
                {/* Contact Name */}
                <Grid container margin='10px 0' display='flex' spacing={1} width='100%'>
                    <Grid item container xs={4} md={3} margin='auto 0' padding='0px 20px' justifyContent='flex-end'> 
                        <Typography variant='generalDataFieldName' >
                        {t("general.contact_name")}:
                        </Typography>
                    </Grid>
                    <Grid  item container xs={8} md={9}> 
                        <TextField
                        name={'contact_name'}
                        required
                        style={{background: '#FFFFFF', minWidth:'70px',width: '90%'}}
                        size='small'
                        type='text'
                        InputProps={{ maxLength: 250 }}
                        defaultValue=''                                         
                        {...register("contact_name", { required: true })}
                        />
                    </Grid> 
                </Grid>
                {/* contact Email */}
                <Grid container margin='10px 0' display='flex' spacing={1} width='100%'>
                    <Grid item container xs={4} md={3} margin='auto 0' padding='0px 20px' justifyContent='flex-end'> 
                        <Typography variant='generalDataFieldName' >
                        {t("general.email")}:
                        </Typography>
                    </Grid>
                    <Grid  item container xs={8} md={9}> 
                        <TextField
                        name={'email'}
                        required
                        style={{background: '#FFFFFF', minWidth:'70px',width: '90%'}}
                        size='small'
                        type='email'
                        InputProps={{ maxLength: 250 }}
                        defaultValue=''                                         
                        {...register("email", { required: true })}
                        />
                    </Grid> 
                </Grid> 
            </Grid>
        </Grid>
        {/* Products */}
        <Grid container>
            <Grid container item xs={12} sx={{padding: '10px', paddingLeft: '20px'}}>
                <Typography variant='subtitle1' padding='10px '>
                {t("product.products")}
                </Typography>
                <Button sx={{marginTop: '16px', height: '30px'}} onClick={()=> AddProduct()} size="small" variant="contained">
                    <Typography variant="yellowButton">
                    {t("general.add")}
                    </Typography>
                </Button>
            </Grid>
            <Grid item xs={12} sx={{padding: '10px', paddingLeft: '20px'}}>
                {selectedProducts.map((product, index) => {
                    return(
                        <Grid container direction="row" justifyContent="felx-start" alignItems="center" spacing={3} sx={{paddingBottom: '10px'}}  key={product.id}>
                            {/**Producto */}
                            <Grid item>
                            <Autocomplete
                                disableClearable
                                value={selectedProducts[index]}
                                onChange={(event, newValue) => {
                                    const prodIndex = [...selectedProducts]
                                    prodIndex[index] = newValue
                                    setSelectedProducts(prodIndex);
                                }}
                                id="controllable-states-demo"
                                options={allProducts}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label={t("product.product")} />}
                                getOptionLabel={(option) => option.name}
                            />
                            </Grid>
                            {/**Cantidad */}
                            <Grid item>
                            <TextField
                            name={t("product.quantity")}
                            required
                            label="Cantidad"
                            style={{background: '#FFFFFF', minWidth:'70px'}}
                            type='number'
                            //InputProps={{ maxLength: 250 }}
                            value={product.quantity}   
                            onChange={(event) => {
                                const prodIndex = [...selectedProducts]
                                prodIndex[index].quantity = event.target.value
                                setSelectedProducts(prodIndex);
                                getsubtotal();
                                console.log('selectedProducts', selectedProducts)
                            }}                                      
                            />
                            </Grid>
                            {/** Costo unitario*/}
                            <Grid item>
                                <TextField
                                name={t("product.unit_cost")}
                                label="Costo unitario"
                                style={{background: '#FFFFFF', minWidth:'70px'}}
                                type='number'
                                value={product.unit_cost[currency]}                                       
                                />
                            </Grid>
                            {/**Total */}
                            <Grid item >
                                <Grid item>Total</Grid>
                                <Grid item>{GeneralQuoteHelper.getCurrencyFormat(product.quantity * product.unit_cost[currency], currency)}</Grid>
                            </Grid>
                            {/**Delete button */}
                            {selectedProducts.length > 1 &&
                                <Grid item>
                                <IconButton onClick={() => deleteProduct(index)} aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                                </Grid>
                            }
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
        {/**Final Data */}
        <Grid container spacing={2} paddingBottom={'50px'} width={'90%'}>
            {/**Subtotal */}
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}> 
                <Grid item >Subtotal</Grid>
                <Grid item xs={4}>
                    {GeneralQuoteHelper.getCurrencyFormat(subTotal,currency)}
                </Grid>
            </Grid>
            {/**Discount */}
            <Grid item container  direction="row" justifyContent="flex-end" alignItems="center" spacing={2}> 
                <Grid item >{t("general.discount")} %</Grid>
                <Grid item xs={4}>
                    <TextField
                    name={'discount'}
                    style={{background: '#FFFFFF', minWidth:'70px',width: '50%'}}
                    size='small'
                    type='number'
                    InputProps={{ maxLength: 250 }}
                    value={discount}    
                    onChange={(event) =>{
                        setDiscount(event.target.value);
                        getTotal(subTotal, event.target.value ,taxes);
                    }}                                    
                    // {...register("company", { required: true })}
                    />
                </Grid>
            </Grid>
            {/**Taxes */}
            <Grid item container  direction="row" justifyContent="flex-end" alignItems="center" spacing={2}> 
                <Grid item >{t("general.taxes")} %</Grid>
                <Grid item xs={4}>
                    <TextField
                    name={'impuesto'}
                    style={{background: '#FFFFFF', minWidth:'70px',width: '50%'}}
                    size='small'
                    type='number'
                    InputProps={{ maxLength: 250 }}
                    value={taxes}    
                    onChange={(event) =>{
                        setTaxes(event.target.value);
                        getTotal(subTotal, discount, event.target.value);
                    }}                                    
                    // {...register("company", { required: true })}
                    />
                </Grid>
            </Grid>
            {/**Payment terms */}
            <Grid item container  direction="row" justifyContent="flex-end" alignItems="center" spacing={2}> 
                <Grid item >{t("general.pay_terms")}</Grid>
                <Grid item xs={4}>
                <Select
                    error={errors.payment}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={''}
                    style={{background: '#FFFFFF', minWidth:'70px',width: '50%'}}
                    label="payment"
                    //onChange={handleChange}
                    {...register("payment", { required: 'Select one option' })}
                >
                    <MenuItem value={0}>{t("quote.pay_terms.cash_pay")}</MenuItem>
                    <MenuItem value={15}>{t("quote.pay_terms.15")}</MenuItem>
                    <MenuItem value={30}>{t("quote.pay_terms.30")}</MenuItem>
                    <MenuItem value={60}>{t("quote.pay_terms.60")}</MenuItem>
                    <MenuItem value={90}>{t("quote.pay_terms.90")}</MenuItem>
                    <MenuItem value={'endMonth'}>{t("quote.pay_terms.end_month")}</MenuItem>
                    <MenuItem value={'endNextMont'}>{t("quote.pay_terms.end_Nmonth")}</MenuItem>
                </Select>
                </Grid>
            </Grid>
            {/**Total */}
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}> 
                <Grid item >Total</Grid>
                <Grid item xs={4}>
                    {GeneralQuoteHelper.getCurrencyFormat(total, currency)}
                </Grid>
            </Grid>
        </Grid>
        </Paper>
        </Box>
        </form>
    </>
)
}